import React from 'react'
import { graphql } from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import { Container } from 'theme-ui'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from 'components/seo'
import OverviewGrid from 'components/OverviewGrid'

export const query = graphql`
  query ArchivePageQuery($language: String!) {
    about: sanityPage(slug: {current: {eq: "about"}}) {
      _rawModules
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      ...LocalizedSettings
    }
    initiatives: allSanityInitiative(
      limit: 50
      sort: { fields: [startedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, i18n_lang: {eq: $language} }
    ) {
      edges {
        node {
          id
          mainImage {
            ...ImageWithHotspot
          }
          categories {
            title {
              en_GB
            }
            invertedIcon {
              asset {
                altText
                url
              }
            }
          }
          title
          slug {
            current
          }
          size
        }
      }
    }
  }
`


function filterForPromoted({ _rawModules }) {
  if (!_rawModules) return [];
  return _rawModules.filter(module => module.promoted);
}


const ArchivePage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <GraphQLErrorList errors={errors} />
    )
  }

  const promotedModules = (data || {}).about
    ? filterForPromoted(data.about)
    : []

  const site = (data || {}).site
  const initiativeNodes = (data || {}).initiatives
    ? mapEdgesToNodes(data.initiatives)
        // .filter(filterOutDocsWithoutSlugs)
        // .filter(filterOutDocsPublishedInTheFuture)
    : []

  // console.log('initiativeNodes: ', data, initiativeNodes)

  if (!site) {
    throw new Error(
      "Missing 'Site settings'. Open the studio at http://localhost:3333 and add some content to 'Site settings' and restart the development server."
    )
  }

  return (
    <React.Fragment>
      <SEO title={site.title} description={site.description?.localized} />
      <Container sx={{ px: 2 }}>
        {initiativeNodes && (
          <OverviewGrid
            title='Latest initiatives'
            nodes={initiativeNodes}
            promoted={promotedModules}
            browseMoreHref='/archive/'
          />
        )}
      </Container>
    </React.Fragment>
  )
}

export default ArchivePage
