/** @jsx jsx */
import React from 'react'
import { jsx, Box, Grid, Heading } from 'theme-ui'
import InitiativeTile from 'components/InitiativeTile'

import BaseBlockContent from '@sanity/block-content-to-react'
import clientConfig from '../../client-config'
import PageRichTextModule from 'components/Modules/PageRichTextModule'
import { sizeToCol } from 'lib/helpers'

function OverviewGrid({ nodes, promoted = [] }) {

  let prev = 0
  // generate series of random numbers each greater than the previous (within range)
  for (let [i, val] of promoted.entries()) {
    const max = i === 0 ? 6 : 20 // limit 1st module to first 10 nodes
    let rand = Math.floor(Math.random() * (max - prev) + prev) + 1
    // attempt to prevent following module to be along side previous module
    if (rand <= prev + 4) {
      rand = rand + 4
    }
    prev = rand
    nodes.splice(rand, 0, val)
  }

  return (
    <Box className='css-grid grid-start' sx={{ listStyle: 'none', mt: 2, mb: 8, p: 0, m: 0}}>
      {nodes &&
        nodes.map(node => (
          <li key={node._key || node.id} sx={{ ...sizeToCol(node.size || (node.promoted && 'injected')) }}>
            {
              node.promoted ?
              <BaseBlockContent
                blocks={[node]}
                serializers={{ types: { richTextModule: (props) => <PageRichTextModule {...props} /> } }}
                {...clientConfig.sanity}
              />
              : <InitiativeTile {...node} />
            }
          </li>
        ))}
  </Box>
  );
}

export default OverviewGrid
